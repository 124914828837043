import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PeriodType } from '@eliq/core'
import { EliqNumberPipe, Period } from '@eliq/core'
import { TranslateService } from '@ngx-translate/core'
import { APIBudget } from '../../models/api-budget.model'
import { BudgetHelperService } from '../../services/budget-helper.service'
import { NgClass, NgIf } from '@angular/common'

@Component({
	selector: 'eliq-budget-home-card-item',
	templateUrl: './budget-home-card-item.component.html',
	styleUrls: ['./budget-home-card-item.component.scss'],
	standalone: true,
	imports: [NgClass, NgIf],
})
export class BudgetHomeCardItemComponent implements OnInit {
	@Input() budget: APIBudget
	@Output() budgetClicked = new EventEmitter()

	public title = ''
	public dateString = ''
	public costString = ''
	public budgetPercentage: number

	public newPeriodMessage = ''
	constructor(
		private budgetHelper: BudgetHelperService,
		private numberPipe: EliqNumberPipe,
		private translator: TranslateService,
	) {}

	ngOnInit(): void {
		const currentPeriod = new Period(
			<PeriodType>this.budget.resolution,
			new Date(),
		)
		this.title = this.budgetHelper.getTranslatedBudgetTitle(this.budget)
		this.dateString =
			this.budgetHelper.getBudgetDateStringFromPeriod(currentPeriod)
		this.costString = this.getCostString(this.budget, currentPeriod)

		if (
			new Date(this.budget.status.period_start) >= currentPeriod.getFirstDate()
		) {
			// the normal case
			this.budgetPercentage =
				(this.budget.status.value / this.budget.limit) * 100
			if (this.budgetPercentage > 100) this.budgetPercentage = 100
		} else {
			this.budgetPercentage = 0
			this.newPeriodMessage = this.translator.instant(
				`budget.${this.budget.resolution + 'ly'}_budget_description`,
			)
		}
	}

	private getCostString(budget: APIBudget, currentPeriod: Period): string {
		let value: number
		const limit = budget.unit === 'energy' ? budget.limit / 1000 : budget.limit

		if (new Date(budget.status.period_start) < currentPeriod.getFirstDate()) {
			value = 0
			return `- / <b>${this.numberPipe.transform(limit, budget.unit)}</b>`
		} else {
			value =
				budget.unit === 'energy'
					? budget.status.value / 1000
					: budget.status.value
			if (value === undefined || Number.isNaN(value)) {
				value = 0
			}
			return `${this.numberPipe.transform(
				value,
				budget.unit,
			)} / <b>${this.numberPipe.transform(limit, budget.unit)}</b>`
		}
	}
}
